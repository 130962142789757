.audioscreen{
    display: none;
}

#footer {
    display: none;
}

.rhap_container {
    width: 100% !important;
}